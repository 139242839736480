import { RouteRecordRaw } from 'vue-router'

const order: RouteRecordRaw = {
	path: '/order',
	name: 'คำสั่งซื้อ',
	component: () => import('@/layout/LayoutDefault.vue'),
	children: [
		{
			path: '',
			name: 'คำสั่งซื้อทั้งหมด',
			component: () => import('@/views/order/index.vue'),
		},
		// {
		// 	path: 'diff',
		// 	name: 'จัดการส่วนต่างออเดอร์',
		// 	component: () => import('@/views/order/order-diff.vue'),
		// },
		{
			path: 'delay',
			name: 'DelayOrder',
			component: () => import('@/views/order/order-delay.vue'),
		},
		{
			path: 'weight-contrast',
			name: 'OrderWeightContrast',
			component: () => import('@/views/order/order-weight-contrast.vue'),
		},
		{
			path: 'oppose',
			name: 'OrderOppose',
			component: () => import('@/views/order/order-oppose.vue')
		},
		{
			path: 'problematic',
			name: 'OrderProblematic',
			component: () => import('@/views/order/order-problematic.vue')
		}
	],
}
export default order
