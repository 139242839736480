import { RouteRecordRaw } from 'vue-router'


const lead: RouteRecordRaw = {
	path: '/lead',
	name: 'LeadMain',
	component: () => import('@/layout/LayoutDefault.vue'),
	children: [
		{
			path: '',
			name: 'LeadList',
			component: () => import('@/views/lead/index.vue')
		}
	]
}



export default lead