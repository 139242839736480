import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import authenticationRoute from './authentication'
import error from './error'
import shopRoute from './shop'
import order from './order'
import dashboard from './dashboard'
import user from './user'
import bookingRoute from './booking'
import temp from './temp'
import reconcile from './reconcile'
import billPayment from './bill-payment'
import claim from './claim'
import courier from './courier'
import system from './system'
import report from './report'
import lead from './lead'

const routes: Array<RouteRecordRaw> = [
	authenticationRoute,
	shopRoute,
	order,
	dashboard,
	user,
	bookingRoute,
	temp,
	reconcile,
	billPayment,
	claim,
	courier,
	system,
	report,
	lead,
	error, // Error Page
]

const router = createRouter({
	history: createWebHistory(),
	routes,
})

export default router
