import { store } from '@/store'
import { RouteRecordRaw } from 'vue-router'

const report: RouteRecordRaw = {
	path: '/report',
	name: 'ReportEmail',
	component: () => import('@/layout/LayoutDefault.vue'),
	children: [
		{
			path: 'email',
			name: 'RootReportEmail',
			component: () => import('@/views/report/email.vue'),
			beforeEnter: async () => {
				const { claims } = store.state.user
                
				const allowRole = ['owner']
				if (!allowRole.includes(claims.role)) {
					// return '/shop'
				}
			}
		},
	]
}
export default report